import router from '@/router'
import CarrierLabelView from "@/modules/storemanagement/components/CarrierLabelView.vue"
import ProformaView from "@/modules/storemanagement/components/ProformaView.vue"

router.addRoutes([
  {
    path: '/:storename/storemanagement/:tab?/:action?/:itemId?/recipients/:recipient?',
    name: 'recipientediting',
    component: () => import('../pages/StoreManagement.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/storemanagement/:tab?/:action?/:itemId?',
    name: 'storemanagement',
    component: () => import('../pages/StoreManagement.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/ordercarriers/pickup-page/:token',
    name: 'storesupplier',
    component: () => import('../pages/StoreSupplier.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:storename/ordercarriers/print-label/:token',
    name: 'printlabel',
    component: () => import('../pages/PrintLabel.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:storename/ordercarriers/print-proforma/:token',
    name: 'printproforma',
    component: () => import('../pages/PrintProforma.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/view-labels',
    name: 'CarrierLabelView',
    component: CarrierLabelView,
    props: (route) => ({ data: JSON.parse(decodeURIComponent(route.query.data)) }),
  },
  {
    path: '/view-proforma',
    name: 'ProformaView',
    component: ProformaView,
    props: (route) => ({ data: JSON.parse(decodeURIComponent(route.query.data)) }),
  },
])
