<template>
  <div class="container-fluid fixed-top nav-bottom-border p-0" style="background-color: #fff">
    <nav class="navbar container navbar-light navbar-expand-md px-md-3">
      <div class="pl-0 pr-4" v-if="showStores" :class="evenCols ? 'col-4' : 'col-3'">
       <p class="greeting-text mt-4">Hello{{ $store.state.profile.user && $store.state.profile.user.first_name ? ' ' + $store.state.profile.user.first_name : ''}} 👋</p>
      </div>
      <div v-else class="pl-0 pr-0" :class="evenCols ? 'col-4' : 'col-3'"></div>
      <div class="pl-3 d-flex justify-content-center" :class="evenCols ? 'col-4' : 'col-6'">
        <div v-if="!activeStore.name && !activeStore.logo">
          <router-link :to="'/'">
            <img height="30" src="@/assets/images/framme_logo_black.png"/>
          </router-link>
        </div>
        <router-link v-else class="router-link-exact-active router-link-active mx-0" :to="'/' + activeStore.path">
          <img v-if="activeStore.logo" height="30" :src="activeStore.logo"/>
          <div v-else class="h6 text-center mb-0">{{activeStore.name}}</div>
        </router-link>
      </div>
      <div class="pr-0" :class="evenCols ? 'col-4' : 'col-3'">
        <div v-if="showLearnMore"  class="d-none d-md-flex justify-content-end">
          <a href="https://www.framme.com" target="_blank"><button class="btn btn-lg btn-outline-primary">Learn more</button></a>
        </div>
        <div class="navbar-collapse d-flex justify-content-end collapse" v-if="showCart">
          <ul class="navbar-nav">
            <NavCart />
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import NavCart from '../../cart/components/NavCart';
import NavMixin from "@/modules/general/mixins/navMixin";


export default {
  name: 'TopNav',
  components: {
    NavCart,
  },
  mixins: [NavMixin],
  props: {
    showLogin: {
      type: Boolean,
      defaul: false
    },
    showLearnMore: {
      type: Boolean,
      default: false
    },
    showStores: {
      type: Boolean,
      default: false
    },
    showCart: {
      type: Boolean,
      default: true
    },
    showDemoButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    evenCols(){
      return this.showDemoButton;
    }
  },
}
</script>

<style scoped>
  .navbar .greeting-text {
    font-family: "Volte-Semibold";
  }
  @media screen and (max-width: 768px) {
  .navbar .greeting-text {
    display: none !important;
  }
}
</style>

