<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showCart=false />
    <SidebarNav />
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mt-5">
          <div class="loader-container" v-if="!tokenChecked || submitStatus=='PENDING'">
            <div class="row justify-content-center">
              <div class="col-md-4 d-flex justify-content-center">
                <div class="loader">Loading...</div>
              </div>
            </div>
          </div>
          <div v-if="validToken && submitStatus!='OK'">
            <h1>Set password</h1>
            <h4 class="welcome">Your new password needs to be at least 8 characters long and contain a number.</h4>
            <div class="form-login">
              <label for="email">New password</label>
              <input class="inputfield form-control" :class="{ 'form-input-error': $v.pw1.$error}" v-model="pw1" type="password" v-on:blur="$v.pw1.$touch()">
              <div v-if="$v.pw1.$error">
                <div class="form-field-error" v-if="!$v.pw1.required">This field is required</div>
                <div class="form-field-error" v-if="!$v.pw1.minLength">Your password need to be at least 8 characters long.</div>
                <div class="form-field-error" v-if="!$v.pw1.notNum && $v.pw1.minLength">Password can't be entirely numeric.</div>
              </div>
              <label for="email">Re-enter new password</label>
              <input class="inputfield form-control" :class="{ 'form-input-error': $v.pw2.$error}" v-model="pw2" type="password" v-on:blur="$v.pw2.$touch()">
              <div v-if="$v.pw2.$error">
                <div class="form-field-error" v-if="!$v.pw2.required">This field is required</div>
                <div class="form-field-error" v-if="!$v.pw2.minLength">Your password need to be at least 8 characters long.</div>
                <div class="form-field-error" v-if="!$v.pw2.notNum && $v.pw2.minLength">Password can't be entirely numeric.</div>
                <div class="form-field-error" v-if="!$v.pw2.sameAsPW1 && $v.pw2.notNum && $v.pw2.minLength">Hmmm. Your passwords don't match.</div>
              </div>
              <button class="btn btn-lg btn-primary btn-block" v-on:click="setPassword()">Set new password</button>
            </div>
          </div>
          <div v-else-if="submitStatus=='OK'">
            <h1>Activation complited</h1>
            <h4 class="welcome">Your password was set and now we'll redirect you to the store.</h4>
          </div>
          <div v-else>
            <h1>This is a bit odd.</h1>
            <h4 class="welcome">This link has expired or already been used. Reset your password again if needed.</h4>
            <button class="btn btn-lg btn-secondary btn-block" type="submit">Reset password</button>
          </div>
        </div> <!--col-4 -->
        <div class="col-md-8">
        <!--Let's add an image here later -->
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import router from '@/router'
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import { required, minLength, sameAs, not, numeric } from 'vuelidate/lib/validators'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";


export default {
  name: 'activate',
  components: {
    SidebarNav,
    TopNav,
    Footer
  },
  data() {
    return {
      validToken: false,
      tokenChecked: false,
      pw1: "",
      pw2: "",
      submitStatus: null
    }
  },
  validations: {
    pw1: {
      required,
      minLength: minLength(8),
      notNum: not(numeric)
    },
    pw2: {
      required,
      minLength: minLength(8),
      notNum: not(numeric),
      sameAsPW1: sameAs('pw1')
    },
  },
  mounted: function () {
    this.checkToken()
  },
  methods:{
    checkToken() {
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + "/api/stores/token_valid/";
      const data = {'token': this.$route.params.token};
      document.body.style.cursor='wait';
      axios({
        method: 'post',
        url: api_url,
        data: data
      })
      .then(function () {
        vm.tokenChecked = true;
        vm.validToken = true;
        document.body.style.cursor='default';
      })
      .catch(function (error) {
        if (error.response && error.response.data){
          vm.$toastr.e(
            error.response.data
          );
        }
        vm.tokenChecked = true;
        vm.validToken = false;
        document.body.style.cursor='default';
      })
    },
    setPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        return
      }
      const vm = this;
      this.submitStatus = 'PENDING'
      const api_url = process.env.VUE_APP_API_URL + "/api/stores/set_password/";
      const data = {
        'token': this.$route.params.token,
        'password1': this.pw1,
        'password2': this.pw2
      }
      axios({
        method: 'post',
        url: api_url,
        data: data
      })
      .then(function (response) {
        vm.submitStatus = 'OK';
        document.body.style.cursor='default';
        const token = response.data.token;
        const stores = response.data.stores;
        vm.$store.dispatch('loginSetup', {token: token, stores: stores, path: response.data.path});
        setTimeout(function() {
          router.push('/' + response.data.path);
        },2500);
      })
      .catch(function (error) {
        if (error.response && error.response.data){
          vm.$toastr.e(
            error.response.data.errors
          );
        }
        vm.submitStatus = 'ERROR'
        document.body.style.cursor='default';
      })
    }
  }
}
</script>