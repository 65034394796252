<template>
  <div id="app" :style="appStyles">
    <router-view/>
  </div>
</template>

<script>
export default {
  created: async function(){
    const vm = this;
    document.addEventListener('chatlio.ready', function () {
      vm.$store.dispatch('chatlioReady');

      if(vm.$store.state.isLoggedIn && vm.$store.state.profileFetched){
        vm.$store.dispatch('chatlioSendUserData');
      }
    }, false);
    document.querySelector('link[rel="icon"]').href = `${process.env.BASE_URL}favicon.ico`
  },
  watch: {
    title(newValue) {
      if(newValue !== null && newValue !== undefined) {
        document.getElementById("store-title").innerText = newValue
        document.getElementById("og-store-title").content = newValue
      }else{
        document.getElementById("store-title").innerText = "Framme Store - Promotional product management platform for busy brand builders."
        document.getElementById("og-store-title").content = "Framme Store - Promotional product management platform for busy brand builders."
      }
    },
    description(newValue) {
      if(newValue !== null && newValue !== undefined) {
        document.getElementById("store-description").content = newValue
        document.getElementById("og-store-description").content = newValue
      }else{
        document.getElementById("store-description").content = "Simplify your brand asset, marketing material and promotional product orders and distribution from all your suppliers and warehouses with Framme."
        document.getElementById("og-store-description").content = "Simplify your brand asset, marketing material and promotional product orders and distribution from all your suppliers and warehouses with Framme."
      }
    },
    favicon(newValue) {
      let favicon = document.querySelector('link[rel="icon"]');
      if(newValue !== null && newValue !== undefined) {
        favicon.href = newValue
      }else{
        favicon.href = `${process.env.BASE_URL}favicon.ico`
      }
    }
  },
  computed: {
    appStyles() {
      let values = {}
      if(this.$store.getters.customerCampaignTemplate){
        values['min-height'] = "100vh !important";
        values['padding-bottom'] = "3rem !important";
        if(this.$store.getters.customerCampaignTemplate.background_color){
          values['background-color'] = this.$store.getters.customerCampaignTemplate.background_color;
        }
        if(this.$store.getters.customerCampaignTemplate.background_image){
          values['background-image'] = 'url("' + this.$store.getters.customerCampaignTemplate.background_image + '")'; // url('{{template.background_image}}')
          values['background-repeat'] = this.$store.getters.customerCampaignTemplate.bg_image_repeat; // {{template.bg_image_repeat|default:'no-repeat'}}
          values['background-position'] = this.$store.getters.customerCampaignTemplate.bg_image_position; // {{template.bg_image_position|default:'center top'}}
          values['background-size'] = this.$store.getters.customerCampaignTemplate.bg_image_size; //  {{template.bg_image_size|default:'auto'}};
        }
      }
      return values;
    },
    title() {
      return this.$store.getters.activeStore.title
    },
    description() {
      return this.$store.getters.activeStore.description
    },
    favicon() {
      return this.$store.getters.activeStore.favicon
    }
  }
}
</script>
