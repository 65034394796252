import router from '@/router'

router.addRoutes([
  {
    path: '/:storename/cart',
    name: 'cart',
    component: () => import('../pages/Cart.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/delivery-details',
    name: 'deliverydetails',
    component: () => import('../pages/DeliveryDetails.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/order-confirmation',
    name: 'orderconfirmation',
    component: () => import('../pages/OrderConfirmation.vue'),
    meta: {
      requiresAuth: true
    }
  },
])
