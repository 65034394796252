<template>
  <div>
    <SamSelect
      :model="selected"
      class="w-100"
      :class="{'sidebar-style': sidebarStyle}"
      label="name"
      :options="allStores"
      :clearable="false"
      @select="selectStore"
      :searchable="true"
    />
  </div>
</template>

<script>
import SamSelect from '../../../components/SamSelect.vue';

export default {
  name: 'NavStoreSelect',
  components: {SamSelect},
  props: {
    sidebarStyle: {type: Boolean}
  },
  data() {
    return {
      selected: this.$store.getters.activeStore.id
    }
  },
  computed: {
    allStores () {
      const stores = this.$store.getters.allStores.map(s => ({
        name: s.status == 1 ? s.name + " *DRAFT*" : s.name,
        id: s.id
      }))
      return stores.sort((a,b) => (a.name > b.name) ? 1 : -1);
    }
  },
  updated: function () {
    if(this.selected == null){
      this.selected = this.$store.getters.activeStore.id;
    }
  },
  methods: {
    selectStore(id){
      this.$store.commit('setActiveStore', id);
      this.$store.dispatch('fetchFilterOptions');
      this.$store.dispatch('admin/clearSuppliers')
      this.$store.commit('clearStoreCarriers')
      this.$emit('closeMenu');
    }
  }
}
</script>

<style scoped>
.sidebar-style {
  background-color: #ffffff;
}
</style>
