<template>
  <router-link :to="{ name: 'cart'}">
    <div class="mx-0 mx-md-2 py-2">
      <span class="cart-item-count">{{cartItemCount}}</span>
      <img class="mb-1 ml-1"
           :src="require(`@/assets/images/${cartItemIcon}`)"
           :width="cartItemIconWidth"
           :height="cartItemIconHeight"
      />
    </div>
  </router-link>
</template>

<script>
import Store from '../../../store'


export default {
  name: 'NavCart',
  data () {
    return {
      cartItemIcon: 'bag-2.svg',
      cartItemIconWidth: 20,
      cartItemIconHeight: 20
    }
  },
  computed: {
    cartItemCount () {
      return Store.getters.cartItemCount;
    }
  },
}
</script>
<style scoped>
.cart-item-count {
  height: 20px;
  width: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
</style>