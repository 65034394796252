import router from '@/router'

router.addRoutes([
  {
    path: '/:storename',
    name: 'productlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/ProductList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/login',
    name: 'storelogin',
    component: () => import(/* webpackChunkName: "about" */ '../../general/pages/Login.vue')
  },
])