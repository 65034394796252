<template>
  <div>
    <iframe :src="proforma" style="width:100%; height:500px;"></iframe>
  </div>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    proforma(){
      return this.data
    }
  }
};
</script>