import Store from "@/store";
import router from "@/router";
export default {
    computed: {
        activeStore() {
            if(!this.$store.getters.activeStore.id && this.$store.getters.isLoggedIn && this.$route.params.storename){
                this.$store.dispatch('fetchStores');
            }
            return this.$store.getters.activeStore;
        },
        stores() {
            return this.$store.getters.allStores;
        },
        multipleStores(){
            if(!this.stores){
                return false;
            }
            return this.stores.length > 1;
        }
    },
    methods: {
        logout() {
            Store.dispatch('logout')
                .then(function () {
                    router.push('/');
                })
        }
    }
}