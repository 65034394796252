<template>
  <div v-if="ready" class="sidebar" :class="{'sidebar-collapsed': minimized}" >
    <div class="sidebar-wrapper">
      <img :src="require(`@/assets/images/${minimized ? 'hamburger.svg' : 'hamburger-close.svg'}`)"
           @click="minimized = !minimized"
           width="16px"
           class="sidebar-collapse-button"
           :class="{'sidebar-minimized': minimized}"
      />
      <header class="sidebar-header pt-4">
        <transition name="fade">
          <NavStoreSelect
              v-if="multipleStores && !minimized"
              :sidebar-style="true"
          />
        </transition>
      </header>
      <div class="sidebar-body">
        <ul class="nav flex-column">
          <sidebar-nav-link
              routeName="productlist"
              icon="inventory.svg"
              :minimized="minimized"
          >Order from inventory</sidebar-nav-link>
          <sidebar-nav-link
              v-if="$store.getters.membershipFetched"
              v-can="'campaign_create'"
              routeName="campaigns"
              icon="magicpen.svg"
              :minimized="minimized"
          >Magic Link campaigns</sidebar-nav-link>
          <sidebar-nav-link
              v-if="$store.getters.membershipFetched"
              v-can="'order_history'"
              routeName="orders"
              icon="box-tick.svg"
              :minimized="minimized"
          >Order history</sidebar-nav-link>
          <br class="sidebar-divider">
          <sidebar-nav-link
              routeName="accountsettings"
              icon="profile-circle.svg"
              :minimized="minimized"
          >Profile</sidebar-nav-link>
          <sidebar-nav-link
              v-if="$store.getters.membershipFetched"
              v-can="'management'"
              routeName="storemanagement"
              icon="setting-2.svg"
              :minimized="minimized"
          >Manage account</sidebar-nav-link>
          <br class="sidebar-divider">
          <li class="nav-item" style="white-space: nowrap" @click="logout">
            <a href="#" target="_self" class="nav-link">
              <img src="@/assets/images/logout.svg" style="width: 20px; margin-right: 11px" />
              <transition name="fade">
              <span v-if="!minimized">
                Logout
              </span>
              </transition>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarNavLink from "@/modules/general/components/sidebar/SidebarNavLink.vue";
import NavStoreSelect from "@/modules/general/components/NavStoreSelect.vue";
import NavMixin from "@/modules/general/mixins/navMixin";
export default {
  components: {
    NavStoreSelect,
    SidebarNavLink
  },
  mixins: [NavMixin],
  data() {
    return {
      sidebarWidth: '303px',
      sidebarMinWidth: '68px',
      minimized: true,
      ready: false
    }
  },
  mounted() {
    this.ready = true
  },  
}
</script>
<style>
:root {
  --sidebar-bg-color: #f4f4f4;
  --sidebar-item-hover: #1a1818;
  --sidebar-item-active: #1a1818;
}
</style>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  color: #9a9a9a;
  background-color: var(--sidebar-bg-color);
  float: left;
  width: 303px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 28px 24px;
  margin: 0;
  outline: 0;
  transition:width 0.3s ease;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  /* z-index: calc(1030 + 5); */
  z-index: 1040;
}
.sidebar-collapsed {
  width: 68px !important;
}
.sidebar .sidebar-header {
  min-height: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.sidebar-collapse-button {
  cursor: pointer;
}
.sidebar .sidebar-divider {
  min-height: 32px;
  height: 32px;
}
.sidebar .nav-link {
  color: #292d32 !important;
  opacity: .3;
}

.nav-link:hover {
  opacity: 1;
}
@media screen and (max-width: 1300px) {
  .sidebar.sidebar-collapsed {
    left: -68px !important;
  }
  .sidebar-collapse-button.sidebar-minimized {
    position: fixed;
    top: 28px;
    left: 92px;
  }
}

</style>