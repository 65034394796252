import router from '@/router'

router.addRoutes([
  {
    path: '/:storename/campaigns/:tab?/:action?/:itemId?',
    name: 'campaigns',
    component: () => import('../pages/Campaigns.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/campaign/:token',
    name: 'publicCampaign',
    component: () => import('../pages/CustomerCampaign.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:storename/campaign/:token/thank-you',
    name: 'paymentSuccess',
    component: () => import('../pages/CustomerCampaignThanks.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:storename/campaign/:token/payment-canceled',
    name: 'paymentCanceled',
    component: () => import('../pages/CustomerCampaignPaymentCanceled.vue'),
    meta: {
      requiresAuth: false
    }
  },
])
