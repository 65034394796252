<template>
  <router-link
      tag="li"
      exact
      :to="{name: routeName}"
      class="nav-item"
      active-class="active"
      :class="{active : this.$route.path.includes(this.routeName)}"
      style="white-space: nowrap"
  >
    <a href="#" target="_self" class="nav-link">
      <img :src="require(`@/assets/images/${icon}`)" style="width: 20px; margin-right: 11px" />
      <transition name="fade">
      <span v-if="!minimized">
        <slot />
      </span>
      </transition>
    </a>
  </router-link>
</template>
<script>
import NavMixin from "@/modules/general/mixins/navMixin";
export default {
  name: "sidebar-nav-link",
  props: {
    routeName: { type: String },
    icon: { type: String },
    minimized: { type: Boolean }
  },
  mixins: [NavMixin]
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.nav-link {
  color: #292d32 !important;
  opacity: .3;
}
.nav-link:hover {
    opacity: 1;
}
.nav-item.active .nav-link {
  opacity: 1;
}
</style>