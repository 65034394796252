import router from '@/router'

router.addRoutes([
  {
    path: '/:storename/products/:slug',
    name: 'productpage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Product.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/leased-products/:slug',
    name: 'leaseproductpage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Product.vue'),
    meta: {
      requiresAuth: true
    }
  }
])
