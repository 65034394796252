<template>
  <div class="footer-background w-100 position-absolute mt-5 pt-5 pb-5">
    <div class="container">
      <footer class="my-5">
        <div class="row">
          <div class="col-12 col-md-3">
            <div class="w-50">
              <img src="@/assets/images/framme_logo_black.png"/>
            </div>
            <div class="mt-3" v-if="$store.state.activeStore && $store.state.activeStore.name">
              {{$store.state.activeStore.name}} is powered<br />by Framme.
            </div>
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>Support</div>
            <div v-if="$store.state.activeStore && $store.state.activeStore.support_email"><a :href="'mailto:' + $store.state.activeStore.support_email + '?subject=Support enquiry from ' + $store.state.activeStore.name + ' – Store'">Contact</a> your company admin</div>
            <div v-if="$store.state.activeStore"><a :href="'mailto:tech@framme.com?subject=Tech enquiry from ' + $store.state.activeStore.name + ' Store'">Contact</a> our tech support</div>
            <div><a href="https://www.framme.com/legal-stuff/" target="_blank">Privacy policy</a></div>
            <div><a href="https://www.framme.com/legal-stuff/" target="_blank">Terms and conditions</a></div>
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>Explore Framme</div>
            <div><a href="https://www.framme.com/" target="_blank">Our website</a></div>
            <div><a :href="apiDocUrl" target="_blank">API Documentation</a></div>
            <div><a href="https://www.instagram.com/weareframme/" target="_blank">Instagram</a></div>
            <div><a href="https://www.linkedin.com/company/framme/" target="_blank">LinkedIn</a></div>
            <!-- <div><a href="https://www.framme.com/newsletter-signup" target="_blank">Subscribe to our newsletter</a></div>-->
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>&copy; Copyright {{ currentYear }},<br />Framme.<br />All rights reserved.</div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      websiteUrl: process.env.VUE_APP_API_URL,
      currentYear: new Date().getFullYear()
    };
  },
  computed: {
    apiDocUrl() {
      return this.websiteUrl + '/api/'
    }
  },
}
</script>