<template>
  <div>
    <div v-for="(parcel, index) in parcels" :key="index">
        <iframe :src="parcel['parcel_label_url']" style="width:100%; height:500px;"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    parcels(){
      const firstParcelLabelUrl = this.data.length > 0 ? this.data[0].parcel_label_url : null;
      const areAllSame = this.data.every(item => item.parcel_label_url === firstParcelLabelUrl);
      return areAllSame ? [this.data[0]] : this.data;
    }
  }
};
</script>