<template>
  <div class="mb-4" >
     <v-select 
      class="sam-input sam-select mb-4"
      v-model="modelValue"
      :label="label"
      :reduce="s => s.id"
      :options="options"
      :placeholder="placeholder"
      :searchable="searchable"
      :multiple="multiple"
      :disabled="disabled"
      :clearable="clearable"
      @input="$emit('select', modelValue)"
     >
     </v-select>
  </div>
</template>

<script>
// import SamLabel from '@/components/fragments/SamLabel.vue'
import vSelect from 'vue-select'

export default {
  components: {
    // SamLabel,
    vSelect
  },
  data() {
    return {
      value: null,
      modelValue: this.model
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: new Array()
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    model: {
      type: Number,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
}
</script>

