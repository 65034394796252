<template>
  <svg width="24px" height="21px" viewBox="0 0 24 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->
    <title>supermarket</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Cart" transform="translate(-1026.000000, -33.000000)" fill="#000000" fill-rule="nonzero">
            <g id="supermarket" transform="translate(1026.000000, 33.000000)">
                <path d="M10,16.4 C8.89719958,16.4 8,17.2971996 8,18.4 C8,19.5028004 8.89719958,20.4 10,20.4 C11.1028004,20.4 12,19.5028004 12,18.4 C11.9999556,17.2971996 11.102756,16.4 10,16.4 Z M10,19.2832086 C9.51293611,19.2832086 9.11670257,18.8869306 9.11670257,18.3999112 C9.11670257,17.9128473 9.51298052,17.5166137 10,17.5166137 C10.4870195,17.5166137 10.8832974,17.9128917 10.8832974,18.3999112 C10.8832974,18.8870195 10.4870195,19.2832086 10,19.2832086 Z" id="Shape"></path>
                <path d="M17.2,16.4 C16.0971996,16.4 15.2,17.2971996 15.2,18.4 C15.2,19.5028004 16.0971996,20.4 17.2,20.4 C18.3028004,20.4 19.2,19.5028004 19.2,18.4 C19.1999556,17.2971996 18.302756,16.4 17.2,16.4 Z M17.2,19.2832086 C16.7129361,19.2832086 16.3167026,18.8869306 16.3167026,18.3999112 C16.3167026,17.9128473 16.7129805,17.5166137 17.2,17.5166137 C17.6870639,17.5166137 18.0832974,17.9128917 18.0832974,18.3999112 C18.0832974,18.8870195 17.6870195,19.2832086 17.2,19.2832086 Z" id="Shape"></path>
                <path d="M23.734772,4.69000308 C23.5100531,4.41797426 23.1770529,4.26198764 22.8212245,4.26198764 L4.46618046,4.26198764 L4.0954927,2.48718912 C4.01810202,2.11696362 3.76207059,1.80609995 3.41046097,1.65552248 L0.824037292,0.548267152 C0.525443334,0.420343332 0.178427466,0.555479337 0.0489117509,0.849884427 C-0.0807445897,1.14438198 0.056271131,1.48668338 0.354724464,1.61442227 L2.94119502,2.72172383 L5.32333667,13.8638859 C5.41339697,14.3788744 5.83892873,14.5833021 6.59993195,14.4771693 L21.1730046,14.4771693 C21.729505,14.4771693 22.2149897,14.0869716 22.3272554,13.5493864 L23.9755222,5.65874747 C24.0472879,5.31515158 23.9595378,4.96198567 23.734772,4.69000308 Z M21.1730046,13.3148979 L6.35716616,13.3148979 L4.70899313,5.42430523 L22.8211777,5.42430523 L21.1730046,13.3148979 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'CartIcon',
};
</script>
