import router from '@/router'

router.addRoutes([
  {
    path: '/:storename/profile',
    name: 'accountsettings',
    component: () => import('../pages/AccountSettings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/orders/:tab?/:itemId?',
    name: 'orders',
    component: () => import('../pages/OrderManagement.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
    if (!to.params.tab) {
      next({ ...to, params: { ...to.params, tab: 'order-history' } })
    } else {
      next()
    }
  }
  },
  {
    path: '/:storename/exportinvoice/:storeId/:orderId',
    name: 'exportinvoice',
    component: () => import('../pages/InvoiceExport.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:storename/deliverynote/:storeId/:orderId',
    name: 'deliverynote',
    component: () => import('../pages/DeliveryNoteExport.vue'),
    meta: {
      requiresAuth: true
    }
  },
])
