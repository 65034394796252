import router from '@/router'
router.addRoutes([
  {
    path: '/offer/:token',
    name: 'publicOffer',
    component: () => import('../pages/Offers.vue'),
    meta: {
      requiresAuth: false
    }
  },
])
