import router from '@/router'
import Activate from '../pages/Activate.vue'
import Landing from '../pages/Landing.vue'

router.addRoutes([
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      title: 'Framme Store - Promotional product management platform for busy brand builders.'
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Login.vue')
  },
  {
    path: '/login/forgot-password',
    name: 'forgot-password',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/ForgotPassword.vue')
  },
  {
    path: '/login/forgot-email-sent',
    name: 'forgot-password-sent',
    component: () => import(/* webpackChunkName: "about" */ '../pages/ForgotPasswordSent.vue')
  },
  {
    path: '/login/set-password/:uid/:token',
    name: 'set-password',
    component: () => import(/* webpackChunkName: "about" */ '../pages/SetPassword.vue')
  },
  {
    path: '/no-active-store',
    name: 'no-active-store',
    component: () => import(/* webpackChunkName: "about" */ '../pages/NoActiveStore.vue')
  },
  {
    path: '/activate/:token',
    name: 'activate',
    component: Activate
  }
])